import React, {useEffect} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"

const VerifyPage = () => {
  useEffect(() => {
    let params = new URLSearchParams(document.location.search.substring(1));
    let userId = params.get('id')
    console.log("userId:", userId);

    fetch('https://europe-west3-schoeffel-green-marketing-2021.cloudfunctions.net/verifyParticipant', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: userId,
        token: "KAFH46jHFAJF857jydfHASGD876DFKAGH8457n457vJFHG746n"
      })
    }).then(r => {
      console.log("Email bestätigt")
    }).catch(error => {
      console.log(error)
    })
  })
  return (
  <Layout>
    <SEO title="Anmeldbestätigung"/>
    <div className="top-area-bg">
      <div className="container">
        <div className="top-area-text-container">
          <h2 className="top-area-text-title">Anmeldung bestätigt!</h2>
        </div>
      </div>
    </div>
    <div className="content-area">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <h2>
              Deine Anmeldung wurde bestätigt! Vielen Dank!
              <br/>
              <br/>
            </h2>
          </div>
        </div>
        <Link to="/" className="btn btn-primary">Zurück zur Aktion</Link>
      </div>
    </div>
  </Layout>
  )
}

export default VerifyPage
